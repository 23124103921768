import request from '@/utils/request_mall'

// 商品列表 
export function GetGoodslist(data) {
  return request({
    method: 'post',
    url: '/goods/goods/list',
    data
  })
}
// 产品/服务详情
export function GetGoodsdetails(data) {
  return request({
    method: 'post',
    url: '/goods/goods/show',
    data
  })
}
// 储值卡详情
export function Getcardsdetails(data) {
  return request({
    method: 'post',
    url: '/goods/goods/money_card_show',
    data
  })
}
// 方案详情
export function Getplansdetails(data) {
  return request({
    method: 'post',
    url: '/goods/goods/proposal_show',
    data
  })
}
//储值卡适用商品列表
export function Getcardsrules(data) {
  return request({
    method: 'post',
    url: '/goods/goods/money_card_rule_goods',
    data
  })
}

// 品牌
export function GetgoodsbrandsoptionsList(data) {
  return request({
    method: 'post',
    url: '/goods/brand/brand_options',
    data
  })
}
// 分类
export function GetgoodsTypesList(data) {
  return request({
    method: 'post',
    url: '/goods/goods/type_options',
    data
  })
}
// 小程序配置
// 获取首页轮播图 
export function Getswippers(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/carousel_info',
    data
  })
}
//保存首页轮播图
export function Saveswippers(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/save_carousel',
    data
  })
}
// 新增类目/分组
export function AddTtypes(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/create_category',
    data
  })
}
// 类目分组树形结构
export function getTypestree(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/category_all',
    data
  })
}
// 分组添加商品
export function AddgroupsGoods(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/category_add_goods',
    data
  })
}
// 分组商品数据
export function GetgroupsGoods(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/category_goods_list',
    data
  })
}
// 修改类目/分组
export function UpdategroupsGoods(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/edit_category',
    data
  })
}
// 删除关联商品
export function DetelegroupsGoods(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/del_category_goods',
    data
  })
}
// 删除类目/分组
export function Deteletypes(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/del_category',
    data
  })
}
// 分组保存商品
export function Savegoods(data) {
  return request({
    method: 'post',
    url: '/operation/miniprogram/category_save_goods',
    data
  })
}